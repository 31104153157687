'use client'

import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

const AdsSourceTracker = () => {
  const searchParams = useSearchParams()

  useEffect(() => {
    const source = searchParams.get('source')
    if (source) {
      localStorage.setItem('source', source)
    }
  }, [searchParams])
  return (
    null
  )
}
export default AdsSourceTracker
